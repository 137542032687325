import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'
import PageHeader from '../../components/header/page-header'
import Layout from '../../components/layout'
import careers from '../../../static/_careers/careers.json'
import SEO from '../../components/seo'

const Careers = () => {
  const data = useStaticQuery(graphql`
    query Careers {
      bgImage: file(relativePath: { eq: "blog/bg-page-title.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const pageHeaderBg = data.bgImage.childImageSharp.fluid
  const pageTitle = 'Careers'

  // console.log(careers.jobs)

  // function convertToSlug(Text) {
  //   return Text.toLowerCase()
  //     .replace(/ /g, "-")
  //     .replace(/[^\w-]+/g, "")
  // }
  return (
    <Layout bgColor>
      <SEO title="Careers | Experienced expertise to Transform the future" />
      <PageHeader
        title={pageTitle}
        subtitle="We are committed to exploring and discovering the best of the IT industry and using our experience and expertise to design IT solutions that can transform the future. "
        background={pageHeaderBg}
        className="pink"
        thisPage={pageTitle}
      />
      <div>
        <div>
          <div className="container-fluid py-5 bg-white">
            <div className="col-md-9 py-4 mx-auto">
              <div className="row">
                <div className="col-md-3 py-4">
                  <div className="d-flex align-content-center">
                    <h1
                      className="my-auto"
                      style={{
                        fontWeight: 600,
                        marginRight: '30px',
                        fontSize: '3.25rem'
                      }}
                    >
                      1
                    </h1>
                    <div>
                      <h4
                        style={{
                          fontWeight: 300,
                          textTransform: 'uppercase',
                          margin: '0px'
                        }}
                      >
                        Teamwork
                      </h4>
                      <p>
                        Working together is paramount to our success. We
                        encourage diversity of opinion and trust.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 py-4">
                  <div className="d-flex align-content-center">
                    <h1
                      className="my-auto"
                      style={{
                        fontWeight: 600,
                        marginRight: '30px',
                        fontSize: '3.25rem'
                      }}
                    >
                      2
                    </h1>
                    <div>
                      <h4
                        style={{
                          fontWeight: 300,
                          textTransform: 'uppercase',
                          margin: '0px'
                        }}
                      >
                        RESPECT
                      </h4>
                      <p>
                        Treat all employees, vendors &amp; partners with
                        respect. We thrive on transparency &amp; honesty.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 py-4">
                  <div className="d-flex align-content-center">
                    <h1
                      className="my-auto"
                      style={{
                        fontWeight: 600,
                        marginRight: '30px',
                        fontSize: '3.25rem'
                      }}
                    >
                      3
                    </h1>
                    <div>
                      <h4
                        style={{
                          fontWeight: 300,
                          textTransform: 'uppercase',
                          margin: '0px'
                        }}
                      >
                        PASSION
                      </h4>
                      <p>
                        We are passionate about the success of our employees,
                        our partners and our products.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 py-4">
                  <div className="d-flex align-content-center">
                    <h1
                      className="my-auto"
                      style={{
                        fontWeight: 600,
                        marginRight: '30px',
                        fontSize: '3.25rem'
                      }}
                    >
                      4
                    </h1>
                    <div>
                      <h4
                        style={{
                          fontWeight: 300,
                          textTransform: 'uppercase',
                          margin: '0px'
                        }}
                      >
                        INNOVATION
                      </h4>
                      <p>
                        Dare to be different in all areas of business, take
                        risks and learn from each other.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="container"
            style={{ paddingTop: '80px', paddingBottom: '80px' }}
          >
            <div className="section-heading text-center">
              <h6
                style={{
                  fontWeight: 'lighter',
                  textTransform: 'uppercase',
                  fontSize: '10px',
                  letterSpacing: '4px',
                  margin: '0px'
                }}
              >
                Join Team Explorelogy
              </h6>
              <div style={{ height: '6px' }} />
              <h2
                style={{
                  margin: '0px',
                  textTransform: 'uppercase',
                  fontSize: '2.25rem',
                  fontWeight: 300
                }}
              >
                Current Oppenings
              </h2>
              <div style={{ height: '10px' }} />
              <div>
                <p>Feel fulfilled. Have fun. Help us to shape the future.</p>
              </div>
              <div style={{ height: '50px' }} />
            </div>
            {careers.jobs ? (
              <div className="job-table">
                <table>
                  <thead>
                    <tr>
                      <th>Role</th>
                      <th className="d-none d-md-table-cell">Type</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {careers.jobs.map((job, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <h6 className="m-0">
                              <Link
                                className="dot"
                                to={`/careers/inquiry/?Job=${job.jobtitle}`}
                                rel="noopener noreferrer"
                              >
                                {job.jobtitle}
                              </Link>
                            </h6>
                          </td>
                          <td className="d-none d-md-table-cell">
                            {job.jobtype}
                          </td>
                          <td className="text-right">
                            <Link
                              to={`/careers/inquiry/?Job=${job.jobtitle}`}
                              className="primary small button"
                              style={{ border: 'none', outline: 'none' }}
                            >
                              Apply Now
                            </Link>
                          </td>
                        </tr>
                      )
                    })}

                    {/* <tr>
                    <td>
                      <h6 className="m-0">
                        <a className="dot" href="/#" rel="noopener noreferrer">
                          Associate Engineer, QA
                        </a>
                      </h6>
                    </td>
                    <td className="d-none d-md-table-cell"> Full Time</td>
                    <td className="text-right">
                      <button
                        className="primary small button"
                        style={{ border: "none", outline: "none" }}
                      >
                        Apply Now
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6 className="m-0">
                        <a className="dot" href="/#" rel="noopener noreferrer">
                          UI/UX Engineer
                        </a>
                      </h6>
                    </td>
                    <td className="d-none d-md-table-cell"> Full Time</td>
                    <td className="text-right">
                      <button
                        className="primary small button"
                        style={{ border: "none", outline: "none" }}
                      >
                        Apply Now
                      </button>
                    </td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>There ara no job oppenings</div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Careers
